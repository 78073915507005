.rating-input {
	@include visually-hidden();
}

.rating-label {
	position: relative;
	width: 1.125rem;
	height: 1.5rem;
	color: $warning;
	cursor: pointer;
}

.rating-star {
	position: absolute;
	top: 0;
	left: 0;
}

.rating-star-half,
.rating-star-fill {
	@include transition(transform 150ms ease-in-out);

	opacity: 0;
	transform: scale(0);
}

/* stylelint-disable selector-max-specificity */
.rating-label:hover .rating-star,
.rating-label:focus .rating-star,
.rating-input:checked + .rating-label:hover .rating-star,
.rating-input:checked + .rating-label:focus .rating-star,
.rating-input:checked + .rating-label .rating-star-fill,
.rating-input:checked + .rating-label .rating-star-half,
.rating-input.is-hover + .rating-label .rating-star-fill,
.rating-input.is-hover + .rating-label .rating-star-half {
	opacity: 1;
	transform: scale(1);
}
/* stylelint-enable */

.rating-star-wrap-half,
.rating-label-half {
	width: 0.875rem;
	height: 1.5rem;
}

.rating-star-wrap-half {
	position: relative;
	display: block;
	overflow: hidden;
}

.rating-label-half {
	top: 0;
	left: -1.125rem;
}

.rating-result {
	width: 2rem;
	text-align: center;
}
