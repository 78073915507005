.profile-button {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.25rem;
	height: 2.25rem;
	padding: 0;
	border: 1px solid transparent;
	border-radius: 0.375rem;
	background: map-get($brand-colors, gray-extra-light);

	&::after {
		display: none;
	}

	&:hover,
	&:focus {
		border-color: map-get($brand-colors, warning-orange);
	}
}

.profile-button.show {
	box-shadow: 0 0 0 0.2rem fade-out(map-get($brand-colors, warning-orange), 0.75);
}
