.password-toggle-wrap > .form-control {
	padding-right: $form-floating-height;
	background-image: none;
}

.password-toggle {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: $form-floating-height;
	height: $form-floating-height;
	padding: 0;
	border: 0;
	background-color: transparent;
	appearance: none;
}

.password-toggle > svg {
	pointer-events: none;
}

.password-toggle > svg:first-child,
.password-toggle.is-visible > svg:last-child {
	display: none;
}

.password-toggle.is-visible > svg:first-child {
	display: block;
}
