.dataTable {
	background: $white;
	font-size: .8125rem;

	.btn {
		font-size: .8125rem;
		padding: .12rem .5rem;
	}
}

.table {
	> :not(caption) > * > * {
		padding: .25rem .5rem;
		vertical-align: middle;
	}

	thead th {
		padding: .5rem;
	}

	tbody tr {
		&:hover {
			background: $body-bg;
		}
	}
}

.dataTables {
	&_filter {
		display: flex;
		font-size: .8125rem;
		margin-bottom: .25rem;
		justify-content: flex-end;

		label {
			align-items: center;
			display: inline-flex;
		}

		input {
			background: $white;
			margin-left: .25rem;
		}
	}

	&_paginate {
		display: flex;
		font-size: .8125rem;
		justify-content: flex-end;

		.page-link {
			font-size: .8125rem;

			&:focus {
				box-shadow: none;
			}
		}
	}

	&_info {
		font-size: .725rem;
	}
}
