.linktable {
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 0.625rem;
}

.linktable__head {
	display: table-header-group;
}

.linktable__body {
	display: table-row-group;
}

.linktable__row {
	display: table-row;
	min-height: 3rem;
	text-decoration: none;
	border-radius: 0.625rem;
	box-shadow: 0 0.25rem 1.25rem -0.625rem fade-out($black, 0.75);

	&:hover,
	&:focus {
		text-decoration: none;
		border-color: $primary;
	}
}

.linktable__cell {
	display: table-cell;
	padding: 0.3125rem;
	font-size: 0.8125rem;
	vertical-align: middle;
	color: $gray-900;
	border: 0;
	background: $white;

	&:first-child {
		border-top-left-radius: 0.625rem;
		border-bottom-left-radius: 0.625rem;
	}

	&:last-child {
		border-top-right-radius: 0.625rem;
		border-bottom-right-radius: 0.625rem;
	}
}

.linktable__head > .linktable__row > .linktable__cell {
	padding: 0.875rem 0.3125rem;
	font-weight: 500;
}

.linktable__body > .linktable__row > .linktable__cell {
	border-style: solid;
	border-color: transparent;
	border-top-width: 1px;
	border-bottom-width: 1px;

	&:first-child {
		border-left-width: 1px;
	}

	&:last-child {
		border-right-width: 1px;
	}
}

.linktable__body > .linktable__row:hover > .linktable__cell,
.linktable__body > .linktable__row:focus > .linktable__cell {
	border-color: $primary;
}

.linktable__cell--logo {
	width: 2.875rem;
}

.linktable__cell--id {
	width: 3.875rem;
}
