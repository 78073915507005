.list-decorated {
	padding-left: map-get($spacers, 3);
}

.list-decorated-item {
	padding-left: map-get($spacers, 1);
	margin-bottom: map-get($spacers, 2);
	font-size: $font-size-sm;

	&::marker {
		color: $blue;
	}
}
