.card-link-shadow {
	@include transition(box-shadow 0.15s ease-in-out);

	box-shadow: $box-shadow-sm;

	&:hover,
	&:focus {
		box-shadow: $box-shadow;
	}
}

.card-tag {
	position: absolute;
	right: .725rem;
	top: .725rem;
	padding: .25rem;
	font-size: .625rem;
	font-weight: $font-weight-bold;
	letter-spacing: 1px;
	color: $white;
	border-radius: .25rem;
	background: $danger-color;
}

.card-inner-height {
	@include media-breakpoint-up(sm) {
		min-height: 5.25rem;
	}

	@include media-breakpoint-up(md) {
		min-height: 2.5rem;
	}

	@include media-breakpoint-up(lg) {
		min-height: 5.75rem;
	}
}
