.smaller {
	font-size: 0.6875rem;
}

.letter-spacing-large {
	letter-spacing: 1px;
}

.text-bluegrey {
	color: map-get($brand-colors, bluegrey);
}
