.form-button-wrap {
	position: relative;
}

.form-button-absolute {
	position: absolute;
	top: 0.25rem;
	right: 0.1875rem;
}

.form-button {
	@include transition($form-button-transition);

	display: flex;
	align-items: center;
	justify-content: center;
	min-height: $input-height;
	padding: 0.375rem 0.9375rem;
	font-weight: 600;
	font-size: $font-size-sm;
	font-family: inherit;
	color: $primary;
	border: 1px solid $primary;
	border-radius: 0.25rem;
	background-color: $body-bg;
	appearance: none;

	&:hover,
	&:focus {
		text-decoration: none;
		background-color: darken($body-bg, 10%);
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.form-button-small {
	font-size: 0.625rem;
}
