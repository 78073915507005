.nav-item {
	margin-right: 0.1875rem;
	margin-left: 0.1875rem;
}

.nav-item .nav-item {
	margin-right: 0;
	margin-left: 0;
}

.nav-tabs .nav-link {
	background-color: $white;
}

.nav-pills .nav-item {
	border-bottom: 1px solid $white;
}

.nav-item > .nav {
	@include border-radius($nav-pills-border-radius);

	overflow: hidden;
	background-color: map-get($brand-colors, gray-extra-light);
}

.nav-collapse.collapsed > svg:last-child {
	transform: rotate(180deg);
}
