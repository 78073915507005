.switcher {
	text-align: left;
}

.switcher-title {
	margin-bottom: 0.625rem;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
	text-align: left;
}

.switcher-box {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0.3125rem;
	overflow: hidden;
	border-radius: 0.3rem;
	background: $gray-200;
}

.switcher-item-box {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.375rem 0.5625rem;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.25rem;
	color: $gray-600;
	border-radius: 0.25rem;
	cursor: pointer;
}

.switcher-item > input:checked ~ .switcher-item-box {
	color: $primary;
	background: $white;
	box-shadow: 0 0.125rem 0.625rem -0.125rem fade-out($black, 0.85);
}
