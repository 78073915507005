.menu-wrap {
	overflow-x: hidden;
}

.menu-wrap-body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	overflow-x: hidden;
}

.menu {
	@include transition(transform $offcanvas-transition-duration ease-in-out);

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 980;
	width: 15rem;
	border-left: 1px solid $gray-300;
	transform: translateX(100%);

	@include media-breakpoint-up(sm) {
		width: 16.25rem;
		border-right: 1px solid $gray-300;
		border-left: 0;
	}

	@include media-breakpoint-up(xl) {
		position: relative;
		visibility: visible;
		transform: translateX(0);
	}
}

.menu.show {
	transform: translateX(0);
}

.menu-backdrop {
	display: none;
}

.menu-backdrop.show {
	z-index: 970;
	display: block;

	@include media-breakpoint-up(xl) {
		display: none;
	}
}

.menu-header {
	z-index: 990;
}

.menu-toggle {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.625rem;
	font-size: 0;
	border: 0;
	border-radius: 0.3125rem;
	background: $white;

	@include media-breakpoint-up(xl) {
		display: none;
	}
}

.menu-toggle > svg {
	pointer-events: none;
}

.menu-toggle > svg:first-child {
	display: block;
}

.menu-toggle > svg:last-child,
.menu-toggle.show > svg:first-child {
	display: none;
}

.menu-toggle.show > svg:last-child {
	display: block;
}

.menu-title {
	font-weight: 600;
	font-size: 0.6875rem;
	letter-spacing: 0.0625rem;
	text-transform: uppercase;
	color: map-get($brand-colors, gray);
}
