@import '~bootstrap/scss/functions';
@import 'variables';

// BOOTSTRAP
// Configuration
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Layout & components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/accordion';
//@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
//@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
//@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
//@import '~bootstrap/scss/popover';
//@import '~bootstrap/scss/carousel';
//@import '~bootstrap/scss/spinners';
//@import '~bootstrap/scss/offcanvas';
@import 'alert';
@import 'bg';
@import 'card';
@import 'form-button';
@import 'forms';
@import 'linktable';
@import 'list';
@import 'menu';
@import 'multiselect';
@import 'nav';
@import 'password-toggle';
@import 'profile-button';
@import 'quill-editor';
@import 'rating';
@import 'status';
@import 'switcher';
@import 'typo';
@import 'user-logo';
@import 'tables';

// Helpers
@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

// Quill editor
@import '~quill/dist/quill.snow.css';
