.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
	appearance: none;
	margin: 0;
}

.form-control[type="number"] {
	appearance: textfield;
}

.form-floating-icon > svg {
	position: absolute;
	top: 50%;
	left: 1rem;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.form-floating-icon.form-floating-icon > input {
	padding-left: 2rem;
}

.form-floating-icon > label {
	left: 1.5rem;
}

.form-floating-clear {
	position: relative;
}

.form-floating-clear.form-floating-clear > input:not(:placeholder-shown) {
	padding-right: 1.75rem;

	@include media-breakpoint-up(lg) {
		padding-right: 2.25rem;
	}
}

.form-clear {
	position: absolute;
	top: 0;
	right: 0;
	width: 1.75rem;
	height: $form-floating-height;
	border: 0;
	background: transparent;
	outline: 0;

	@include media-breakpoint-up(lg) {
		width: 2.25rem;
	}
}

.form-clear > svg {
	pointer-events: none;
}

.form-floating-clear > input:placeholder-shown ~ .form-clear {
	display: none;
}
