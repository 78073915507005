.status-wrap {
	position: relative;
}

.status {
	position: absolute;
	top: 0.125rem;
	left: 0.125rem;
	display: block;
	width: 0.4rem;
	height: 0.4rem;
}
