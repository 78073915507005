.multiselect {
	position: relative;
}

.multiselect-content {
	position: absolute;
	top: calc(#{$form-floating-height} + 0.375rem);
	left: -0.375rem;
	z-index: 100000;
	display: none;
	width: 19rem;
	max-width: 100vw;
	max-height: 22rem;
	padding: 0.375rem;
	overflow: auto;
	border-radius: 0.625rem;
	background-color: $white;
	box-shadow: 0 0.375rem 1.875rem rgba(0, 0, 0, 0.3);
}

.multiselect:focus-within > .multiselect-content {
	display: block;
}

.multiselect > svg {
	position: absolute;
	top: calc(#{$form-floating-height} / 2);
	right: 0.5rem;
	transform: translateY(-50%);
	pointer-events: none;
}

.multiselect:focus-within > svg {
	transform: translateY(-50%) rotate(180deg);
}

.multiselect-list > li > .multiselect-list {
	padding-left: 1.5rem;
}

.multiselect-check {
	padding: 0.75rem 0.5rem 0.75rem 2rem;
	font-size: 0.875rem;
	border-radius: 0.3125rem;
	background-color: $white;

	&:hover {
		background-color: $gray-100;
	}
}

.multiselect-check.multiselect-check > input {
	margin-top: 0.1875rem;
	margin-left: -1.5rem;
}

.multiselect.multiselect > input {
	padding-right: 2.25rem;

	&:not(:disabled) {
		background-color: $white;
	}
}

.multiselect-collapse {
	font-size: 0.875rem;

	&:focus {
		box-shadow: none;
	}
}

.multiselect-collapse.collapsed > svg {
	transform: rotate(180deg);
}

.multiselect > input:not(:placeholder-shown) ~ svg {
	display: none;
}
