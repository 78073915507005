.user-logo {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.25rem;
	max-width: 100%;
	overflow: hidden;
	border: 1px solid transparent;
	border-radius: 0.375rem;
	background: map-get($brand-colors, gray-extra-light);

	.dataTable & {
		margin: 0 auto;
	}

	&::before {
		content: '';
		position: relative;
		display: block;
		padding-top: 100%;
	}
}

.user-logo > img {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.user-logo-md {
	width: 2.5625rem;
}

.user-logo-lg {
	width: 4.4375rem;
}

.user-logo-xl {
	width: 11.75rem;
}
