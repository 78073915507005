$brand-colors: (
	'warning-orange': #f06337,
	'warning': #ffc107,
	'green': #57ba82,
	'green-dark': #28a745,
	'blue': #007bff,
	'danger': #dc3545,
	'main': #83a7a8,
	'main-dark': #1995a9,
	'bluegrey': #8b9cb7,
	'bluegrey-dark': #155d69,
	'bluegrey-darker': #17263d,
	'turquoise-light': #e8f6f8,
	'gray-dark': #0e1b1c,
	'gray': #6c757d,
	'gray-light': #ced4da,
	'gray-lighter': #e8ecee,
	'gray-lightest': #e9ecef,
	'gray-extra-light': #f6f8fa,
);

$success: map-get($brand-colors, green);
$primary: map-get($brand-colors, main-dark);
$secondary: map-get($brand-colors, gray-lighter);
$gray-100: map-get($brand-colors, gray-lighter);
$gray-300: map-get($brand-colors, gray-light);
$gray-900: map-get($brand-colors, bluegrey-darker);
$white: #fff;
$success: map-get($brand-colors, green-dark);
$body-bg: map-get($brand-colors, gray-extra-light);
$danger-color: map-get($brand-colors, danger);

$min-contrast-ratio: 3;

$font-family-sans-serif:
	'Poppins', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji',
	'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$link-decoration: none;
$link-color: map-get($brand-colors, blue);
$link-hover-decoration: underline;
$link-hover-color: $link-color;

$headings-font-weight: 600;

$card-border-radius: 0.625rem;

$box-shadow: 0 4px 20px -10px rgba(0, 0, 0, 0.25);

$input-font-size: 0.875rem;
$input-box-shadow: inset 0 1px 2px rgba($success, 0.075);
$btn-font-size: $input-font-size;
$form-button-transition: background-color 150ms ease-in-out;
$form-floating-height: add(2.125rem, 2px);
$form-floating-padding-x: 0.5rem;
$form-floating-padding-y: 0.375rem;
$form-floating-input-padding-t: 0.75rem;
$form-floating-input-padding-b: 0;
$form-floating-label-transform: scale(0.69) translateY(-0.35rem) translateX(0.26rem);

$nav-link-font-size: 0.8125rem;
$nav-link-font-weight: 500;
$nav-link-color: map-get($brand-colors, bluegrey-darker);
$nav-link-hover-color: $nav-link-color;

$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-border-color: $primary;

$nav-pills-link-active-color: map-get($brand-colors, bluegrey-dark);
$nav-pills-link-active-bg: map-get($brand-colors, turquoise-light);

$pagination-color: map-get($brand-colors, bluegrey-darker);
$pagination-active-color: $primary;
$pagination-active-bg: $white;
$pagination-active-border-color: $gray-300;
